.App {
  text-align: center;
  background-color: black;
  min-height: 100vh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-family: 'Orbitron';
}

.App-container-text {
  font-family: "Orbitron";
}

h1 {
  font-family: "Orbitron";
  font-size: larger;
}